/*---------------------*\
 * IMPORT Components
\*---------------------*/
import {
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
    ALL_PRODUCT_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    NEW_REVIEW_RESET,

    ADMIN_PRODUCT_REQUEST,
    ADMIN_PRODUCT_SUCCESS,
    ADMIN_PRODUCT_FAIL,

    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    NEW_PRODUCT_RESET,

    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_RESET,

    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_RESET,

    ALL_REVIEW_REQUEST,
    ALL_REVIEW_SUCCESS,
    ALL_REVIEW_FAIL,

    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    DELETE_REVIEW_RESET,

    CLEAR_ERRORS,

    CLEAR_PRODUCT_DETAILS
} from "../constants/productConstants"



/*--------------------------*\
 * GET ALL PRODUCTS REDUCER 
\*--------------------------*/
export const productReducer = (state = { products: [] }, action) => {

    switch (action.type) {
        case ALL_PRODUCT_REQUEST:
        case ADMIN_PRODUCT_REQUEST:
            return {
                loading: true,
                products: [],
            };
        // ###################
        case ALL_PRODUCT_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                productsCount: action.payload.productsCount,
                resultPerPage: action.payload.resultPerPage,
            };
        // ###################
        case ADMIN_PRODUCT_SUCCESS:
            return {
                loading: false,
                products: action.payload,
            };
        // ###################
        case ALL_PRODUCT_FAIL:
        case ADMIN_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        // ###################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ###################
        default:
            return state;
    }
};



/*---------------------------*\
 * GET PRODUCT DETAILS REDUCER 
\*---------------------------*/
export const productDetailsReducer = (state = { products: {} }, action) => {

    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload,
            };
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case CLEAR_PRODUCT_DETAILS:
            return {
                loading: false,
                products: {},
            };
        default:
            return state;
    }
};


/*---------------------------*\
 * NEW REVIEW REDUCER 
\*---------------------------*/
export const newReviewReducer = (state = {}, action) => {
    switch (action.type) {
        // ################
        case NEW_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        // ################
        case NEW_REVIEW_SUCCESS:
            return {
                loading: false,
                success: action.payload,
            };
        // ################
        case NEW_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        // ################
        case NEW_REVIEW_RESET:
            return {
                ...state,
                success: false,
            };
        // ################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ################
        default:
            return state;
    }
};



/*------------------------------*\
 * NEW PRODUCT REDUCER --Admin
\*------------------------------*/
export const newProductReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        // ################
        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        // ################
        case NEW_PRODUCT_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                product: action.payload.product,
            };
        // ################
        case NEW_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        // ################
        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false,
            };
        // ################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ################
        default:
            return state;
    }
};


/*-----------------------------------------*\
 * DELETE & UPDATE PRODUCT REDUCER -- Admin
\*-----------------------------------------*/
export const deleteAndUpdateProductReducer = (state = {}, action) => {
    switch (action.type) {
        // ################
        case DELETE_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        // ################
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            };

        // ################
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };
        // ################
        case DELETE_PRODUCT_FAIL:
        case UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        // ################
        case DELETE_PRODUCT_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        // ################
        case UPDATE_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        // ################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ################
        default:
            return state;
    }
};




/*--------------------------------*\
 * ALL REVIEWS REDUCER -- Admin
\*--------------------------------*/
export const productReviewsReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
        // ################
        case ALL_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        // ################
        case ALL_REVIEW_SUCCESS:
            return {
                loading: false,
                reviews: action.payload,
            };
        // ################
        case ALL_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // ################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ################
        default:
            return state;
    }
};


/*----------------------------------------*\
 * UPDATE & DELETE REVIEWS REDUCER -- Admin
\*----------------------------------------*/
export const reviewReducer = (state = {}, action) => {
    switch (action.type) {
        // ################
        case DELETE_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        // ################
        case DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            };
        // ################
        case DELETE_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        // ################
        case DELETE_REVIEW_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        // ################
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // ################
        default:
            return state;
    }
};




