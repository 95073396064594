import React, { Fragment, useEffect } from "react";
/*---------------------*\
 * IMPORT Components
\*---------------------*/
import "./OrderDetails.css";
import MetaData from "../layout/MetaData";
import { getOrderDetails, clearErrors } from "../../actions/orderAction";
import Loader from "../layout/Loader/Loader";
import { formatNumber } from "../FormatNumber/formatNumber";


/*---------------------*\
 * IMPORT Npm
\*---------------------*/
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useAlert } from "react-alert";
import { useParams, useNavigate } from "react-router-dom";



// ########################
const OrderDetails = () => {
    const { order, error, loading } = useSelector((state) => state.orderDetails);

    // ########################
    const dispatch = useDispatch();
    const alert = useAlert();
    const { id } = useParams();
    const navigate = useNavigate();

    // ########################
    useEffect(() => {
        dispatch(getOrderDetails(id));
    }, [dispatch, id]);


    // ########################
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
            navigate("/ordernotfound");
        }
    }, [dispatch, alert, error, id, navigate])


    // ########################
    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="Order Details" />
                    {order && (
                        <div className="orderDetailsPage">
                            <div className="orderDetailsContainer">
                                <Typography component="h1">
                                    Order #{order && order._id}
                                </Typography>
                                <Typography>Shipping Info</Typography>
                                <div className="orderDetailsContainerBox">
                                    <div>
                                        <p>Name:</p>
                                        <span>{order.user && order.user.name}</span>
                                    </div>
                                    <div>
                                        <p>Phone:</p>
                                        <span>
                                            {order.shippingInfo && order.shippingInfo.phoneNo}
                                        </span>
                                    </div>
                                    <div>
                                        <p>Address:</p>
                                        <span>
                                            {order.shippingInfo &&
                                                `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                                        </span>
                                    </div>
                                </div>
                                <Typography>Payment</Typography>
                                <div className="orderDetailsContainerBox">
                                    <div>
                                        <p
                                            className={
                                                order.paymentInfo &&
                                                    order.paymentInfo.status === "succeeded"
                                                    ? "greenColor"
                                                    : "redColor"
                                            }
                                        >
                                            {order.paymentInfo &&
                                                order.paymentInfo.status === "succeeded"
                                                ? "PAID"
                                                : "NOT PAID"}
                                        </p>
                                    </div>

                                    <div>
                                        <p>Amount:</p>
                                        <span>{formatNumber(order.totalPrice && order.totalPrice)}</span>
                                    </div>
                                </div>

                                <Typography>Order Status</Typography>
                                <div className="orderDetailsContainerBox">
                                    <div>
                                        <p
                                            className={
                                                order.orderStatus && order.orderStatus === "Delivered"
                                                    ? "greenColor"
                                                    : "redColor"
                                            }
                                        >
                                            {order.orderStatus && order.orderStatus}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="orderDetailsCartItems">
                                <Typography>Order Items:</Typography>
                                <div className="orderDetailsCartItemsContainer">
                                    {order.orderItems &&
                                        order.orderItems.map((item) => (
                                            <div key={item.product}>
                                                <img src={item.image} alt="Product" />
                                                <Link to={`/product/${item.product}`}>
                                                    {item.name}
                                                </Link>{" "}
                                                <span>
                                                    {item.quantity} X {formatNumber(item.price)} ={" "}
                                                    <b>{formatNumber(item.price * item.quantity)}</b>
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default OrderDetails;
