import React from 'react'


/*---------------------*\
 * IMPORT Npm
\*---------------------*/
import Helmet from "react-helmet";


const MetaData = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
}

export default MetaData