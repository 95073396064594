import React, { Fragment, useEffect, useState } from "react";
/*---------------------*\
 * IMPORT Components
\*---------------------*/
import MetaData from "../layout/MetaData";
import {
    getOrderDetails,
    clearErrors,
    updateOrder,
} from "../../actions/orderAction";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import "./ProcessOrder.css";
import SideBar from "./Sidebar";
import Loader from "../layout/Loader/Loader";
import { formatNumber } from "../FormatNumber/formatNumber";


/*---------------------*\
 * IMPORT Npm
\*---------------------*/
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Button } from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";



// ####################
const ProcessOrder = () => {
    // ####################
    const { id } = useParams();
    const navigate = useNavigate();

    // ####################
    const { order, error, loading } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.order);

    // ####################
    const updateOrderSubmitHandler = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        myForm.set("status", status);

        dispatch(updateOrder(id, myForm));
    };


    // ####################
    const dispatch = useDispatch();
    const alert = useAlert();
    const [status, setStatus] = useState("");


    // ####################
    useEffect(() => {
        dispatch(getOrderDetails(id));
    }, [dispatch, id]);



    // ####################
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
            navigate("/ordernotfound")
        }

        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors());
        }

        if (isUpdated) {
            alert.success("Order Updated Successfully");
            dispatch({ type: UPDATE_ORDER_RESET });
        }
    }, [dispatch, alert, error, isUpdated, updateError, navigate])


    // ####################
    return (
        <Fragment>
            <MetaData title="Process Order" />
            <div className="dashboard">
                <SideBar />
                <div className="newProductContainer">
                    {loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            {order && (
                                <div
                                    className="confirmOrderPage"
                                    style={{
                                        display: order.orderStatus === "Delivered" ? "block" : "grid",
                                    }}
                                >
                                    <div>
                                        <div className="confirmshippingArea">
                                            <Typography>Shipping Info</Typography>
                                            <div className="orderDetailsContainerBox">
                                                <div>
                                                    <p>Name:</p>
                                                    <span>{order.user && order.user.name}</span>
                                                </div>
                                                <div>
                                                    <p>Phone:</p>
                                                    <span>
                                                        {order.shippingInfo && order.shippingInfo.phoneNo}
                                                    </span>
                                                </div>
                                                <div>
                                                    <p>Address:</p>
                                                    <span>
                                                        {order.shippingInfo &&
                                                            `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                                                    </span>
                                                </div>
                                            </div>

                                            <Typography>Payment</Typography>
                                            <div className="orderDetailsContainerBox">
                                                <div>
                                                    <p
                                                        className={
                                                            order.paymentInfo &&
                                                                order.paymentInfo.status === "succeeded"
                                                                ? "greenColor"
                                                                : "redColor"
                                                        }
                                                    >
                                                        {order.paymentInfo &&
                                                            order.paymentInfo.status === "succeeded"
                                                            ? "PAID"
                                                            : "NOT PAID"}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p>Amount:</p>
                                                    <span>{formatNumber(order.totalPrice && order.totalPrice)}</span>
                                                </div>
                                            </div>

                                            <Typography>Order Status</Typography>
                                            <div className="orderDetailsContainerBox">
                                                <div>
                                                    <p
                                                        className={
                                                            order.orderStatus && order.orderStatus === "Delivered"
                                                                ? "greenColor"
                                                                : "redColor"
                                                        }
                                                    >
                                                        {order.orderStatus && order.orderStatus}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="confirmCartItems">
                                            <Typography>Your Cart Items:</Typography>
                                            <div className="confirmCartItemsContainer">
                                                {order.orderItems &&
                                                    order.orderItems.map((item) => (
                                                        <div key={item.product}>
                                                            <img src={item.image} alt="Product" />
                                                            <Link to={`/product/${item.product}`}>
                                                                {item.name}
                                                            </Link>{" "}
                                                            <span>
                                                                {item.quantity} x {formatNumber(item.price)} ={" "}
                                                                <b>{formatNumber(item.price * item.quantity)}</b>
                                                            </span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div
                                        style={{
                                            display: order.orderStatus === "Delivered" ? "none" : "block",
                                        }}
                                    >
                                        <form
                                            className="updateOrderForm"
                                            onSubmit={updateOrderSubmitHandler}
                                        >
                                            <h1>Process Order</h1>

                                            <div>
                                                <AccountTreeIcon />
                                                <select onChange={(e) => setStatus(e.target.value)}>
                                                    <option value="">Choose Category</option>
                                                    {order.orderStatus === "Processing" && (
                                                        <option value="Shipped">Shipped</option>
                                                    )}

                                                    {order.orderStatus === "Shipped" && (
                                                        <option value="Delivered">Delivered</option>
                                                    )}
                                                </select>
                                            </div>

                                            <Button
                                                id="createProductBtn"
                                                type="submit"
                                                disabled={
                                                    loading ? true : false || status === "" ? true : false
                                                }
                                            >
                                                Process
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default ProcessOrder;
